import React, { useEffect, useState } from 'react';
import { storage } from '../../firebase-config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, setDoc } from 'firebase/firestore';

const VersionUploadPopup = ({ isOpen, onClose, db }) => {
    const [show, setShow] = useState(isOpen);
    const [apkFile, setApkFile] = useState(null);
    const [apkVersionCode, setApkVersionCode] = useState('');
    const [appVersion, setAppVersion] = useState('');
    const [versionDescription, setVersionDescription] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        if (isOpen) {
            setShow(true);
            resetForm();
        } else {
            setShow(false);
            setTimeout(onClose, 200);
        }
    }, [isOpen, onClose]);

    const resetForm = () => {
        setApkFile(null);
        setApkVersionCode('');
        setAppVersion('');
        setVersionDescription('');
        setUploadProgress(0);
    };

    const handleClose = () => {
        resetForm();
        setShow(false);
        setTimeout(onClose, 200);
    };

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file && file.name.endsWith('.apk')) {
            setApkFile(file);
            setUploadProgress(0);
        } else {
            alert('Please select a valid APK file');
            event.target.value = ''; // Reset file input
            setApkFile(null);
        }
    };

    const handleFileUpload = async (file) => {
        if (!file) return null;
        
        const fileName = `${file.name}`;
        const storageRef = ref(storage, `ZennoApp/app_updates/${fileName}`);
        
        try {
            return new Promise((resolve, reject) => {
                const uploadTask = uploadBytesResumable(storageRef, file);

                uploadTask.on('state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setUploadProgress(Math.round(progress));
                    },
                    (error) => {
                        console.error('Upload error:', error);
                        reject(error);
                    },
                    async () => {
                        const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
                        resolve(downloadUrl);
                    }
                );
            });
        } catch (error) {
            console.error('Error uploading file:', error);
            throw error;
        }
    };

    const handleSubmit = async () => {
        if (!apkFile || !apkVersionCode || !appVersion || !versionDescription) {
            alert('Please fill in all fields');
            return;
        }

        try {
            setIsUploading(true);
            
            // Upload APK file and get URL
            const uploadedApkUrl = await handleFileUpload(apkFile);
            
            // Create new version document
            const versionDoc = {
                apk_url: uploadedApkUrl,
                apk_version_code: Number(apkVersionCode),
                app_version: appVersion,
                versionDescription: versionDescription,
                created_at: new Date().toISOString()
            };

            // Save to Firestore
            await setDoc(doc(db, 'Companies/Zeeno_Dev/app_updates', `${appVersion}-${apkVersionCode}`), versionDoc);
            
            alert('Version uploaded successfully!');
            handleClose();
        } catch (error) {
            console.error('Error uploading version:', error);
            alert('Error uploading version. Please try again.');
        } finally {
            setIsUploading(false);
        }
    };

    const isFormValid = apkFile && apkVersionCode && appVersion && versionDescription;

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full transition-opacity duration-200 ${show ? 'opacity-100' : 'opacity-0'} z-20`}
            onClick={handleClose}
            style={{ display: show || isOpen ? 'block' : 'none' }}
        >
            <div
                className="relative top-20 mx-auto p-6 border w-[600px] shadow-lg rounded-md bg-white"
                onClick={e => e.stopPropagation()}
            >
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-semibold">Upload New Version</h2>
                    <button 
                        onClick={handleClose}
                        className="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-1.5"
                    >
                        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <div className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">APK File</label>
                        <input
                            type="file"
                            accept=".apk"
                            onChange={handleFileSelect}
                            className="w-full p-2 border rounded-md bg-gray-50"
                        />
                        {apkFile && (
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Selected file: {apkFile.name}
                                </p>
                                {isUploading && (
                                    <div className="mt-2">
                                        <div className="w-full bg-gray-200 rounded-full h-2.5">
                                            <div
                                                className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                                                style={{ width: `${uploadProgress}%` }}
                                            ></div>
                                        </div>
                                        <p className="text-sm text-gray-600 mt-1">
                                            Upload progress: {uploadProgress}%
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Version Code</label>
                        <input
                            type="number"
                            value={apkVersionCode}
                            onChange={(e) => setApkVersionCode(e.target.value)}
                            className="w-full p-2 border rounded-md bg-gray-50"
                            placeholder="e.g. 1001"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">App Version</label>
                        <input
                            type="text"
                            value={appVersion}
                            onChange={(e) => setAppVersion(e.target.value)}
                            className="w-full p-2 border rounded-md bg-gray-50"
                            placeholder="e.g. 1.0.1"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Version Description</label>
                        <textarea
                            value={versionDescription}
                            onChange={(e) => setVersionDescription(e.target.value)}
                            className="w-full p-2 border rounded-md bg-gray-50 h-32 resize-none"
                            placeholder="Enter version changes and updates..."
                        />
                    </div>
                </div>

                <div className="mt-6 flex justify-center">
                    <button
                        onClick={handleSubmit}
                        disabled={!isFormValid || isUploading}
                        className={`px-6 py-2 rounded-full ${
                            isFormValid && !isUploading
                                ? 'bg-[#152FF5] text-white hover:bg-[#1229d6]'
                                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                        }`}
                    >
                        {isUploading ? 'Uploading...' : 'Upload Version'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VersionUploadPopup;