import React, { useEffect } from 'react'
import logo from '../../assets/images/zeeno_logo.png'
import exitIcon from "../../assets/icons/ic_exit.svg"
import NavbarHome from '../NavbarHome'
import { useAtom } from 'jotai'
import { loggedInUser, selectedPage } from '../store/DataStore'
import ActionsFragment from '../fragments/ActionsFragment'
import TasksFragment from '../fragments/TasksFragment'
import MapFragment from '../fragments/MapFragment'
import VideosFragment from '../fragments/VideosFragment'
import UsersFragment from '../fragments/UsersFragment'
import SupportFragment from '../fragments/SupportFragment'
import SettingsFragment from '../fragments/SettingsFragment'
import { collection, getDocs, query, updateDoc, where } from 'firebase/firestore'
import { db } from '../../firebase-config'
import { useNavigate } from 'react-router-dom'

function Homepage() {
  const navigate = useNavigate();
  const [selected] = useAtom(selectedPage)
  const [currentUser] = useAtom(loggedInUser);

  useEffect(() => {
    document.title = "Zeeno | Home";
    refreshActive(currentUser.email, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refreshActive = async (userEmail, value) => {
    console.log('refreshActive called');
    if (!userEmail) {
      console.error('Invalid user email');
      return;
    }

    try {
      const usersCollectionRef = collection(db, 'Companies/Zeeno_Dev/Users');
      const q = query(usersCollectionRef, where("email", "==", userEmail));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log('No user found with the given email');
        return;
      }
      const userDoc = querySnapshot.docs[0];
      const userDocRef = userDoc.ref;

      await updateDoc(userDocRef, {
        isActive: value
      });

      console.log('User lastLogin timestamp updated successfully');
    } catch (error) {
      console.error('Error updating user lastLogin: ', error);
    }
  };

  const renderContent = () => {
    switch (selected) {
      case 0: return <ActionsFragment userName={currentUser.name}/>;
      case 1: return <TasksFragment />;
      case 2: return <MapFragment />
      case 3: return <VideosFragment userName={currentUser.name}/>
      case 4: return <UsersFragment userName={currentUser.name}/>
      case 5: return <SupportFragment />
      case 6: return <SettingsFragment userName={currentUser.name} />

      default: return <ActionsFragment userName={currentUser.name}/>;
    }
  };

  return (
    <div className='flex flex-row select-none 2xl:h-screen lg:h-fit overflow-hidden'>
      <div className='w-72 bg-[#3C5BD2] flex flex-col py-5'>
        <div className='px-10'>
          <img src={logo} alt="logo" className='h-9 select-none' />
        </div>
        <NavbarHome />
        <div className='flex justify-center 2xl:mt-[300px] lg:mt-16'>
          <div className='bg-[#7685fc] rounded-lg flex flex-row py-[7px] px-5 w-fit cursor-pointer items-center' onClick={() => {
            refreshActive(currentUser.email, false)
            navigate("/")
          }}>
            <img src={exitIcon} alt='exit' className='h-5' />
            <span className='text-white ml-3 font-heebo text-md'>{currentUser.name}</span>
          </div>
        </div>
      </div>
      <div className='w-full'>
        {renderContent()}
      </div>
    </div>
  )
}

export default Homepage