import React, { useEffect, useState } from 'react'
import Header from '../Header'
import TaskModel from '../models/TaskModel'
import { useAtom } from 'jotai'
import { currentRobot } from '../store/DataStore'
import { useTasks } from '../helpers/useTasks'
import { doc, onSnapshot } from 'firebase/firestore'
import { db } from '../../firebase-config'

const TasksFragment = () => {
  const [savedRobot] = useAtom(currentRobot);
  const taskList = useTasks(savedRobot);
  const [currNetworkStatus, setCurrNetworkStatus] = useState('')

  const getTaskType = (taskContent) => {
    switch (taskContent[0]) {
      case 'goto': 
        return { type: 'Go To', content: [taskContent[1]] };
        case 'escort': 
        return { type: 'Escort', content: [taskContent[1], '->', taskContent[3]].join(' ') };
      case 'fetch': 
        return { type: 'Fetch', content: [taskContent[1], '->', taskContent[3]].join(' ') };
      case 'video':
        return { type: 'Video', content: [taskContent[2]]};
      default: 
        return { type: taskContent[0], content: taskContent[0] };
    }
  };

  const getTaskStatus = (taskContent) => {
    switch (taskContent) {
      case 'paused': return 'At Hold';
      case 'active': return 'In Progress';
      case 'upcoming': return 'Upcoming';
      default: return taskContent;
    }
  };

  async function getCurrentPing(db) {
    if (!savedRobot || savedRobot.trim() === '') {
      console.log("No robot saved");
      return;
    }

    const docPath = `Companies/Zeeno_Dev/Actions/${savedRobot}/Ping/pingsetting`;
    const docRef = doc(db, docPath);

    onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const isOnline = data.is_online;
        console.log("Current online status: ", isOnline);
        if (isOnline) {
          setCurrNetworkStatus('Online');
        } else {
          setCurrNetworkStatus('Offline');
        }
      } else {
        console.log("Document does not exist!");
      }
    }, (error) => {
      console.error("Error listening to document: ", error);
    })
  }

  useEffect(() => {
    if (savedRobot) {
      getCurrentPing(db);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db, savedRobot]);

  const sortedTaskList = taskList ? [...taskList].sort((a, b) => {
    if (a.status === 'paused' && b.status !== 'paused') return -1;
    if (a.status !== 'paused' && b.status === 'paused') return 1;
    if (a.status === 'active' && b.status !== 'active') return -1;
    if (a.status !== 'active' && b.status === 'active') return 1;
    return new Date(a.timestamp) - new Date(b.timestamp);
  }) : [];

  return (
  <div className='bg-[#F0F2FF] px-10 py-6 lg:h-[600px] 2xl:h-full overflow-auto'>
    <Header />
    <div className='flex flex-row w-fit 2xl:mt-14 lg:mt-8 items-center'>
      <h1 className='font-heebo text-xl  text-[#273a84]'>Tasks</h1>
    </div>
    {sortedTaskList && sortedTaskList.map((item, index) => {
      const taskInfo = getTaskType(item.content);
      return (
        <TaskModel
          key={index}
          robotName={item.robot_name}
          networkStatus={currNetworkStatus}
          batteryAmount={item.battery_amount ? item.battery_amount : 0}
          wifiStatus={item.wifi_status}
          nextTasks={item.next_tasks ? item.next_tasks : 0}
          task={taskInfo.type}
          taskContent={taskInfo.content}
          duration={item.status === 'complete' ? 100 : 50}
          status={getTaskStatus(item.status)}
          taskId={item.id}
        />
      );
    })}
  </div>
);
}

export default TasksFragment