import React, { useEffect, useState } from 'react';
import statusIcon from '../../assets/icons/ic_statuspatient.svg';
import maskIcon from '../../assets/icons/ic_mask.svg';
import { collection, query, where, doc, getDoc, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { useAtom } from 'jotai';
import { currentRobot } from '../store/DataStore';
import EscortCheckedinPopup from './EscortCheckedinPopup';

const CheckedinPopup = ({ isOpen, children, onClose, userName, savedNickname, batteryAmount, locations, currentLocation }) => {
    const [show, setShow] = useState(isOpen);
    const [patientsList, setPatientsList] = useState([]);
    const [savedRobot] = useAtom(currentRobot);

    // State to manage EscortPopup visibility and parameters
    const [isEscortPopupOpen, setIsEscortPopupOpen] = useState(false);
    const [escortName, setEscortName] = useState('');
    const [escortChair, setEscortChair] = useState('');
    const [escortAreaSelected, setEscortAreaSeleced] = useState('');

    useEffect(() => {
        if (savedRobot !== '') {
            const unsubscribe = fetchPatients(savedRobot);
            fetchAreaSelected();
            console.log("Locations: ", locations.toString());
    
            // Cleanup function to unsubscribe from the listener
            return () => {
                unsubscribe();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedRobot]);

    const fetchPatients = (savedRobot) => {
        const q = query(
            collection(db, "Companies", "Zeeno_Dev", "Dolphin", savedRobot, "Appointments"), 
            where("Confirmed", "==", true), 
            orderBy("OriginalTime")
        );
    
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const patients = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            console.log("Patients are ", patients);
            setPatientsList(patients);
        }, (error) => {
            console.error("Error fetching patients: ", error);
        });
    
        // Return the unsubscribe function to clean up the listener
        return unsubscribe;
    };

    const fetchAreaSelected = async () => {
        const settingsPath = `/Companies/Zeeno_Dev/Settings/${savedRobot}/Kiosk/kiosksetting`;
        const settingsDocRef = doc(db, settingsPath);
        try {
            const settingsDocSnap = await getDoc(settingsDocRef);
            if (settingsDocSnap.exists()) {
                const settingsData = settingsDocSnap.data();
                const areaSelected = settingsData.location || "unknown";
                console.log('Area selected:', areaSelected);
                setEscortAreaSeleced(areaSelected);
            } else {
                console.log("Settings document does not exist!");
            }
        } catch (error) {
            console.error('Error fetching document: ', error);
        }
    };

    useEffect(() => {
        if (isOpen) {
            setShow(true);
        } else {
            setShow(false);
            setTimeout(onClose, 200);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, onClose]);

    const handleClose = () => {
        setShow(false);
        setTimeout(onClose, 200);
    };

    const openEscortPopup = (name, chair) => {
        setEscortName(name);
        setEscortChair(chair);
        setIsEscortPopupOpen(true);
        handleClose(); // Close the CheckedinPopup
    };

    return (
        <>
            <div
                className={`fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full transition-opacity duration-200 ${show ? 'opacity-100' : 'opacity-0'}`}
                onClick={handleClose}
                style={{ display: show || isOpen ? 'block' : 'none' }}
            >
                <div
                    className="relative top-20 mx-auto p-4 border w-fit shadow-lg rounded-md bg-white ml"
                    onClick={e => e.stopPropagation()}
                >
                    <div className="flex justify-end">
                        <button onClick={handleClose} className="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-0.5 text-sm ml-auto inline-flex items-center">
                            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div className="-mt-4 flex flex-col items-center">
                        {children}
                    </div>

                    <div className='mt-4'>
                        {patientsList.map((item, index) => (
                            <div key={index}>
                                <PatientModel name={item.FirstName} time={item.ScheduledTime} chair={item.Chair} doctor={item.LocationName} id={item.Id} savedRobot={savedRobot} userName={userName} savedNickname={savedNickname} batteryAmount={batteryAmount} locations={locations} onCheckPressed={openEscortPopup} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* EscortPopup component */}
            <EscortCheckedinPopup
                isOpen={isEscortPopupOpen}
                onClose={() => setIsEscortPopupOpen(false)}
                onSend={() => { /* Handle send action here if needed */ }}
                locations={locations} // Pass the locations array here
                currentLocation={escortChair} // Pass the current location if needed
                escortNameFromCheckedIn = {escortName}
                escortLocationFromCheckedIn={escortChair}
                escortLocationToCheckedIn={escortAreaSelected}
                batteryAmount={batteryAmount}
                userName={userName}
                savedNickname={savedNickname}
            />
        </>
    );
};

const PatientModel = ({ name, time, chair, doctor, id, savedRobot, userName, savedNickname, batteryAmount, onCheckPressed }) => {
    const [checkInBtn] = useState("Escort");



    // Updated to only handle opening the EscortPopup
    const onCheckPressedInternal = async () => {
        
        console.log("The name is " + name + " and the chair is " + chair + " and the robot is " + savedRobot);

        // Call the parent component's onCheckPressed function to open the EscortPopup
        onCheckPressed(name, chair);
    };

    return (
        <div className='flex flex-row h-14 bg-[#8290F8] px-2 bg-opacity-10 rounded-l-[100px] rounded-r-3xl items-center mb-2.5 border-l-[#8290F8] border-l-[5px]'>
            <img alt='patient_status' src={statusIcon} className='h-10' />
            <div className='flex flex-col w-32 ml-2'>
                <span className='font-heebo text-md'>{name}</span>
                <span className='font-heebo font-light text-sm'>Patient</span>
            </div>
            <div className='flex flex-col w-36'>
                <span className='font-heebo text-md'>{time}</span>
                <span className='font-heebo font-light text-sm'>Scheduled Time</span>
            </div>
            <div className='flex flex-col w-20'>
                <span className='font-heebo text-md'>{chair}</span>
                <span className='font-heebo font-light text-sm'>Chair</span>
            </div>
            <img alt='doctor' src={maskIcon} className='h-7 -mt-4' />
            <div className='flex flex-col w-32 ml-3'>
                <span className='font-heebo text-md'>{doctor}</span>
                <span className='font-heebo font-light text-sm'>Doctor</span>
            </div>
            <div className={`flex w-fit bg-[#152FF5] bg-opacity-80 hover:bg-opacity-100 py-1 px-3 rounded-lg items-center cursor-pointer ${checkInBtn === "Checked" && 'bg-opacity-100'}`} onClick={onCheckPressedInternal}>
                <span className='text-white font-heebo font-light'>{checkInBtn}</span>
            </div>
        </div>
    );
};

export default CheckedinPopup;
