import React, { useEffect, useRef, useState } from 'react'
import temiIcon from '../../assets/images/temi_image.png'
import greenDot from '../../assets/icons/ic_greendot.svg'
import redDot from '../../assets/icons/ic_reddot.svg'
import batteryFull from '../../assets/icons/ic_batteryfull.svg'
import batteryThird from '../../assets/icons/ic_batterythird.svg'
import batteryHalf from '../../assets/icons/ic_batteryhalf.svg'
import batteryLow from '../../assets/icons/ic_batterylow.svg'
import wifiFull from '../../assets/icons/ic_wififull.svg'
import wifiHalf from '../../assets/icons/ic_wifihalf.svg'
import wifiLow from '../../assets/icons/ic_wifilow.svg'
import arrowRight from '../../assets/icons/ic_arrowright.svg'
import menuIcon from '../../assets/icons/ic_menu.svg'
import { db } from '../../firebase-config'
import { deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore'
import { useAtom } from 'jotai'
import { currentRobot } from '../store/DataStore'

const TaskModel = ({ robotName, networkStatus, batteryAmount, wifiStatus, nextTasks, task, taskContent, status, taskId }) => {
    const [batteryIcon, setBatteryIcon] = useState(batteryFull);
    const [wifiIcon, setWifiIcon] = useState(wifiFull);
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef();
    const [savedRobot] = useAtom(currentRobot);
    const [pauseTitle, setPauseTitle] = useState("");

    useEffect(() => {
        const onChangeBatteryIcon = () => {
            if (batteryAmount > 90) {
                setBatteryIcon(batteryFull);
            } else if (batteryAmount < 90 && batteryAmount > 50) {
                setBatteryIcon(batteryThird);
            } else if (batteryAmount < 50 && batteryAmount > 20) {
                setBatteryIcon(batteryHalf);
            } else if (batteryAmount < 20) {
                setBatteryIcon(batteryLow);
            }
        }
        const onChangeWifi = () => {
            switch (wifiStatus) {
                case 'Good':
                    setWifiIcon(wifiFull);
                    break;
                case 'Okay':
                    setWifiIcon(wifiHalf);
                    break;
                case 'Poor':
                    setWifiIcon(wifiLow);
                    break;
                default:
                    setWifiIcon(wifiLow);
                    break;
            }
        };
        onChangeBatteryIcon();
        onChangeWifi();
    }, [batteryAmount, wifiStatus]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    const onPlayPauseTask = () => {
        console.log('Document ID is: ', taskId);
        setShowMenu(false);
        if (pauseTitle === "Pause Task") {
            updateTaskStatus(taskId, "paused");
        } else {
            updateTaskStatus(taskId, "active");
        }

    };
    

    const onCancelTask = () => {
        setShowMenu(false);
        updateTaskStatus(taskId, "canceled");
        updateRefreshStatus(savedRobot);
    };

    const updateRefreshStatus = async (savedRobot) => {
        const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/actions`;
        const docRef = doc(db, docPath);
        try {
          await updateDoc(docRef, {
            is_refresh: true,
          });
        } catch (error) {
          console.error('error updated location: ', error);
        }
    }
    
    const updateTaskStatus = async (taskId, status) => {
        const collectionPath = `Companies/Zeeno_Dev/Actions/${savedRobot}/Logs`;
        try {
            const taskRef = doc(db, collectionPath, taskId);
            if (status === "canceled") {
                await deleteDoc(taskRef);
                console.log(`Document successfully deleted for taskId: ${taskId} in robot: ${savedRobot}`);
            } else if (status === "paused") {
                await updateDoc(taskRef, { 
                    status: status,
                    is_running: false
                });
                console.log(`Status successfully updated to '${status}' for taskId: ${taskId} in robot: ${savedRobot}`);
            }
            else if(status === "active"){
                await updateDoc(taskRef, { 
                    status: status,
                    is_running: true
                });
                console.log(`Status successfully updated to '${status}' for taskId: ${taskId} in robot: ${savedRobot}`);    
            }
        } catch (error) {
            console.error(`Error updating/deleting document for taskId: ${taskId} in robot: ${savedRobot}`, error);
        }
    };

    const getTaskStatus = async (taskId) => {
        const collectionPath = `Companies/Zeeno_Dev/Actions/${savedRobot}/Logs`;
        try {
            const taskRef = doc(db, collectionPath, taskId);
            const docSnapshot = await getDoc(taskRef);

            if (docSnapshot.exists()) {
                console.log(`Status for taskId: ${taskId} is '${docSnapshot.data().status}'`);
                
                return docSnapshot.data().status; // Returns the status of the task
            } else {
                console.log(`No document found for taskId: ${taskId}`);
                return null;
            }
        } catch (error) {
            console.error(`Error getting document for taskId: ${taskId}`, error);
            return null;
        }
    };

    return (
        <div className='flex flex-row mt-5'>
            <div className='lg:w-[970px] 2xl:w-[1150px] h-20 bg-[#8290F8] rounded-l-[100px] rounded-r-3xl'>
                <div className='w-[99.8%] h-20 bg-[#FFFF] rounded-l-[100px] rounded-r-3xl ml-1 flex flex-row p-4 items-center'>
                    <img src={temiIcon} alt='temilogo' className='2xl:h-12 lg:h-10' />
                    <div className='pl-4 mt-2'>
                        <h1 className='font-heebo 2xl:text-xl lg:text-md 2xl:w-[140px] lg:w-[120px] font-medium'>{robotName}</h1>
                        <div className='flex flex-row'>
                            <img src={networkStatus === 'Offline' ? redDot : greenDot} alt='dot' className='2xl:h-2.5 lg:h-2 2xl:mt-1 lg:mt-1.5' />
                            <span className='ml-2 text-gray-400 font-heebo font-light 2xl:text-sm lg:text-[14px]'>{networkStatus}</span>
                        </div>
                    </div>
                    <div className='mt-1.5 2xl:pl-9 lg:pl-3'>
                        <div className='flex flex-row'>
                            <img src={batteryIcon} alt='battery' className='h-5' />
                            <span className=' font-heebo 2xl:text-xl lg:text-lg ml-1'>{batteryAmount + '%'}</span>
                        </div>
                        <span className='ml-7 text-gray-400 2xl:text-md lg:text-sm font-light'>Battery</span>
                    </div>
                    <div className='mt-1.5 pl-9'>
                        <div className='flex flex-row'>
                            <img src={wifiIcon} alt='wifi' className='h-5' />
                            <span className=' font-heebo 2xl:text-xl lg:text-lg ml-1'>{wifiStatus}</span>
                        </div>
                        <span className='ml-7 text-gray-400 2xl:text-md lg:text-sm font-light'>WIFI</span>
                    </div>
                    <div className='mt-1.5 flex flex-col pl-6'>
                        {/* <span className='font-heebo text-xl ml-2'>{nextTasks}</span>
                        <span className='ml-2 text-gray-400 text-md lg:text-sm font-light w-16'>Next Tasks</span> */}
                    </div>
                    <div className='mt-1.5 flex flex-col pl-9'>
                        <span className='font-heebo 2xl:text-xl lg:text-lg ml-2'>{task}</span>
                        <span className='ml-2 text-gray-400 text-md lg:text-sm font-light 2xl:w-18 lg:w-20'>Current Task</span>
                    </div>
                    {/* <div className='mt-1 ml-9'>
                        <ProgressBar
                            bgcolor="#3C5BD2"
                            progress={duration}
                            height={9}
                        />
                        <span className='ml-2 text-md font-light'>Duration</span>
                    </div> */}
                    <div className='mt-1.5 flex flex-col pl-9' style={{ width: '300px', height: '50px' }}>
                        <span className='font-heebo 2xl:text-xl lg:text-lg ml-2'>{taskContent}</span>
                        <span className='ml-2 text-gray-400 text-md lg:text-sm font-light 2xl:w-18 lg:w-20'>Description</span>
                    </div>
                    <span className='2xl:text-2xl lg:text-md font-heebo text-[#3C5BD2] 2xl:ml-16 lg:ml-5'>{status}</span>
                    <img src={arrowRight} alt='arrowright' className='ml-12' />

                </div>
            </div>
            <div className='flex flex-row'>
                <img src={menuIcon} alt='menu' className='ml-4 cursor-pointer' onClick={async () => {
                    setShowMenu(!showMenu);
                    const status = await getTaskStatus(taskId);
                    console.log("Task Status:", status);
                    if (status === 'active') {
                        setPauseTitle("Pause Task");
                    } else {
                        setPauseTitle("Resume Task");
                    }
                }} />
                {showMenu && (
                    <div ref={menuRef} className='absolute mt-7 -ml-[175px] w-48 bg-[#DCE0FF] rounded-xl shadow-xl z-20'>
                        {status !== 'Upcoming' && <button className='block px-4 py-3 font-heebo font-light text-lg text-gray-700 hover:bg-[#e7eaff] w-full text-left rounded-t-xl' onClick={onPlayPauseTask}>{pauseTitle}</button>}
                        <button className='block px-4 py-3 font-heebo text-lg text-red-500 hover:bg-[#e7eaff] w-full text-left rounded-b-xl' onClick={onCancelTask}>Cancel Task</button>
                    </div>
                )}
            </div>

        </div>

    )
}



export default TaskModel