/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import Header from '../Header'
import locationIcon from '../../assets/icons/ic_location.svg'
//import followMe from '../../assets/icons/ic_followme.svg'
import targetIcon from '../../assets/icons/ic_target.svg'
import escortIcon from '../../assets/icons/ic_escort.svg'
import speakerIcon from '../../assets/icons/ic_speaker.svg'
import messageIcon from '../../assets/icons/ic_message.svg'
//import screenIcon from '../../assets/icons/ic_screen.svg'
import videosIcon from '../../assets/icons/ic_videotape.svg'
import qrIcon from '../../assets/icons/ic_qrcode.svg'
import checkIcon from '../../assets/icons/ic_check.svg'
import fetchIcon from '../../assets/icons/ic_fetch.svg'
import IconTint from 'react-icon-tint';
import Footer from '../models/TaskModel'
import { collection, doc, getDoc, getDocs, onSnapshot, query, Timestamp, updateDoc, where, addDoc } from 'firebase/firestore'
import { db } from '../../firebase-config'
import { useAtom, useSetAtom } from 'jotai'
import { checkedPatientIds, currentPatientList, currentRobot, escortFrom, escortName, escortTo, fetchFrom, fetchItem, fetchWaitTime, isCheckinOpen,isCheckedinOpen, isComeHereOpen, isDisplayMessageOpen, isEscortOpen, isFetchOpen, isGotoOpen, isPageChanged, isQrOpen, isShowAnnounce, locationArray, qrCodeTitle, robotNickname, savedAnnounced, savedMessage, savedSelectedLoc, selectedPage, webUrl } from '../store/DataStore'
import GotoPopup from '../popups/GotoPopup'
import MessagePopup from '../popups/MessagePopup'
import AnnouncePopup from '../popups/AnnouncePopup'
import EscortPopup from '../popups/EscortPopup'
import FetchPopup from '../popups/FetchPopup'
import QRPopup from '../popups/QRPopup'
import ComeHerePopup from '../popups/ComeHerePopup'
import CheckinPopup from '../popups/CheckinPopup'
import CheckedinPopup from '../popups/CheckedinPopup'
import axios from 'axios';
import qs from 'qs';


const actionBtns = [
  { id: 1, image: locationIcon, text: 'Come Here' },
  // { id: 2, image: followMe, text: 'Follow Me' },
  { id: 2, image: targetIcon, text: 'Go To' },
  { id: 2, image: escortIcon, text: 'Escort' },
  { id: 2, image: fetchIcon, text: 'Fetch' },
  { id: 2, image: speakerIcon, text: 'Announce' },
  { id: 2, image: messageIcon, text: 'Show Message' },
  // { id: 2, image: screenIcon, text: 'Clear Screen' },
  { id: 2, image: videosIcon, text: 'Play Video' },
  { id: 2, image: qrIcon, text: 'Show QR Code' },
  { id: 2, image: checkIcon, text: 'Check In Patient' },
  { id: 2, image: checkIcon, text: 'Checked In Patients' },
];

const ActionsFragment = ( {userName} ) => {
  const [gotoStatus, setGotoStatus] = useAtom(isGotoOpen);
  const [escortStatus, setEscortStatus] = useAtom(isEscortOpen);
  const [fetchStatus, setFetchStatus] = useAtom(isFetchOpen);
  const [showMessageStatus, setShowMessageStatus] = useAtom(isDisplayMessageOpen);
  const [showAnnounce, setShowAnnounce] = useAtom(isShowAnnounce);
  const [showQRStatus, setShowQRStatus] = useAtom(isQrOpen);
  const [comeHereStatus, setComeHereStatus] = useAtom(isComeHereOpen);
  const [checkinStatus, setCheckinStatus] = useAtom(isCheckinOpen);
  const [checkedinStatus, setCheckedinStatus] = useAtom(isCheckedinOpen);
  const [savedRobot] = useAtom(currentRobot);
  const [savedNickname] = useAtom(robotNickname);
  const [selectedLocation, setSelectedLocation] = useAtom(savedSelectedLoc);
  const [displayedMessage] = useAtom(savedMessage);
  const [announcedMessage] = useAtom(savedAnnounced);
  const [escortFromSaved] = useAtom(escortFrom);
  const [escortToSaved] = useAtom(escortTo);
  const [escortNameSaved] = useAtom(escortName);
  const [fetchItemSaved] = useAtom(fetchItem);
  const [fetchWaitTimeSaved] = useAtom(fetchWaitTime);
  const [fetchFromSaved] = useAtom(fetchFrom);
  const [qrTitleSaved] = useAtom(qrCodeTitle)
  const [qrUrlSaved] = useAtom(webUrl);
  const [checkedIds] = useAtom(checkedPatientIds);
  const [savedPatientList] = useAtom(currentPatientList);

  const [currNetworkStatus, setCurrNetworkStatus] = useState('');
  const [currBatteryAmount, setCurrBatteryAmount] = useState(0);
  const [currWifiStatus, setCurrWifiStatus] = useState('');
  const [currNextTasks, setCurrNextTasks] = useState(0);
  const [currStatus, setCurrStatus] = useState('');
  const [currTaskType, setCurrTaskType] = useState('');
  const [taskId, setTaskId] = useState('')
  const [isTaskVisible, setIsTaskVisible] = useState(false);
  const [locArray, setlocArray] = useAtom(locationArray);
  const [rawStatus, setRawStatus] = useState('');
  const intervalRef = useRef();
  const [currentLoc, setCurrentLoc] = useState('');
  const [kioskLoc, setKioskLoc] = useState('');
  const [currTaskContent, setCurrTaskContent] = useState('');

  const startTimer = () => {
    //TODO - CHANGE INTERVAL TO APPROXIMATE INTERVAL
    // if (!intervalRef.current) {
    //   intervalRef.current = setInterval(() => {
    //     setDuration(prevDuration => prevDuration + 1);
    //   }, 400);
    // }
  };
  const pauseTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };
  const resetTimer = () => {
    pauseTimer();
    // setDuration(0);
  };

  // const getPracticeId = async () => {
  //   const practiceIdPath = `Companies/Zeeno_Dev/Dolphin/${savedRobot}`;
  //   const practiceIdRef = doc(db, practiceIdPath);
  // try {
  //     const docSnap = await getDoc(practiceIdRef);
  //     if (docSnap.exists()) {
  //         const practiceId = docSnap.data().practiceid;
  //         console.log('Practice ID:', practiceId);
  //         return practiceId;
  //     } else {
  //         console.log('No such document!');
  //     }
  // } catch (error) {
  //     console.error('Error getting document:', error);
  // }
// };
// async function fetchPatientsFromDB(savedRobot){
//     const practiceId = await getPracticeId();
//     let data = JSON.stringify({
//         "practiceId": practiceId,
//         "robotSN": savedRobot
//         });

//         let config = {
//         method: 'post',
//         maxBodyLength: Infinity,
//         url: 'https://zeeno-api.azurewebsites.net/bot-appointments',
//         headers: { 
//             'Content-Type': 'application/json'
//         },
//         data : data
//         };

//         axios.request(config)
//         .then((response) => {
//         console.log(JSON.stringify(response.data));
//         })
//         .catch((error) => {
//         console.log(error);
//         });
// };  

  async function getCurrentLocation(db) {
    if (savedRobot !== '') {
      const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/actions`;
      const docRef = doc(db, docPath);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const currentLocation = docSnap.data().current_location;
          setCurrentLoc(currentLocation);
          return currentLocation;
        } else {
          console.log("No such document!");
          return [];
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
        return [];
      }
    } else {
      console.log("No robot saved");
      return [];
    }
  }

  async function getKioskLocation(db) {
    if (savedRobot !== '') {
      const settingsPath = `/Companies/Zeeno_Dev/Settings/${savedRobot}/Kiosk/kiosksetting`;
      const settingsDocRef = doc(db, settingsPath);
      try {
        const settingsDocSnap = await getDoc(settingsDocRef);
        if (settingsDocSnap.exists()) {
            const settingsData = settingsDocSnap.data();
            const areaSelected = settingsData.location || "unknown";
            console.log('Area selected:', areaSelected);
            setKioskLoc(areaSelected);
        } else {
            console.log("Settings document does not exist!");
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
        return [];
      }
    } else {
      console.log("No robot saved");
      return [];
    }
  }

  async function getLocationList(db) {
    if (savedRobot !== '') {
      const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Locations/backuplocations`;
      const docRef = doc(db, docPath);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const locations = docSnap.data().locations;
          setlocArray(locations);
          console.log("Locations: ", locations);
          return locations;
        } else {
          console.log("No such document!");
          return [];
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
        return [];
      }
    } else {
      console.log("No robot saved");
      return [];
    }
  }

  async function getCurrentTask(db) {
    if (!savedRobot) {
      setIsTaskVisible(false);
      return;
    }

    const collectionPath = `Companies/Zeeno_Dev/Actions/${savedRobot}/Logs`;
    const collectionRef = collection(db, collectionPath);

    const onTaskUpdate = (querySnapshot) => {
      if (!querySnapshot.empty) {
        const firstDocData = querySnapshot.docs[0].data();
        setRawStatus(firstDocData.status);
        setTaskId(querySnapshot.docs[0].id);
      }
      const tasks = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      })).filter(data => data.status === "active" || data.status === "paused");
      
      if (tasks.length > 0) {
        updateTaskUI(tasks[0]);
      } else {
        setIsTaskVisible(false);
        console.log("No active or paused tasks found");
      }
    };

    const updateTaskUI = (taskData) => {
      setIsTaskVisible(true);
      setCurrBatteryAmount(taskData.battery_amount ? taskData.battery_amount : 0);
      setCurrWifiStatus(taskData.wifi_status);
      setCurrNextTasks(taskData.next_tasks ? taskData.next_tasks : 0);
      
      // Use the new getTaskType function to get both type and content
      const taskInfo = getTaskType(taskData.content);
      setCurrTaskType(taskInfo.type);
      setCurrTaskContent(taskInfo.content);
      
      setCurrStatus(taskData.status === 'active' ? 'In Progress' : 
                  (taskData.status === 'paused' ? 'At Hold' : taskData.status));
    };

    const getTaskType = (taskContent) => {
      switch (taskContent[0]) {
        case 'goto': 
          return { type: 'Go To', content: [taskContent[1]] };
        case 'escort': 
          return { type: 'Escort', content: [taskContent[1], '->', taskContent[3]].join(' ') };
        case 'fetch': 
          return { type: 'Fetch', content: [taskContent[1], '->', taskContent[3]].join(' ') };
        case 'video':
          return { type: 'Video', content: [taskContent[2]]};
        default: 
          return { type: taskContent[0], content: taskContent[0] };
      }
    };

    try {
      onSnapshot(collectionRef, onTaskUpdate);
    } catch (error) {
      setIsTaskVisible(false);
      console.error("Error listening to documents: ", error);
    }
  }

  async function getCurrentPing(db) {
    if (!savedRobot || savedRobot.trim() === '') {
      console.log("No robot saved");
      return;
    }

    const docPath = `Companies/Zeeno_Dev/Actions/${savedRobot}/Ping/pingsetting`;
    const docRef = doc(db, docPath);

    onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const isOnline = data.is_online;
        console.log("Current online status: ", isOnline);
        if (isOnline) {
          setCurrNetworkStatus('Online');
        } else {
          setCurrNetworkStatus('Offline');
        }
      } else {
        console.log("Document does not exist!");
      }
    }, (error) => {
      console.error("Error listening to document: ", error);
    });
  }

  useEffect(() => {
    getCurrentTask(db);
    getLocationList(db);
    if (savedRobot) {
      getCurrentPing(db);
      //fetchPatientsFromDB(savedRobot)
    }
  }, [db, savedRobot]);

  useEffect(() => {
    getLocationList(db);
    getCurrentLocation(db);
    getKioskLocation(db);
  }, [gotoStatus, escortStatus, fetchStatus, showQRStatus, comeHereStatus])

  useEffect(() => {
    if (rawStatus === 'active') {
      startTimer();
    } else if (rawStatus === 'paused' || currNetworkStatus === 'Offline') {
      pauseTimer();
    } else if (rawStatus === 'done') {
      resetTimer();
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [rawStatus, db]);


  const sendToLocation = async () => {
    const logPath = `Companies/Zeeno_Dev/Actions/${savedRobot}/Logs`;
    const collectionRef = collection(db, logPath);      
    // Create a new document in the collection and get its ID
    const newDocRef = await addDoc(collectionRef, {});         
    // Create a reference to the document we just created
    const docRef = doc(db, `${logPath}/${newDocRef.id}`);          
    // Create the log entry object with the dynamic areaSelected
    const action = {
      by: userName,
      description: "Going to " + selectedLocation,
      status: "upcoming",
      timestamp: Timestamp.now(), // Use Timestamp.now() for the current time
      content: [
        "goto",
        selectedLocation ? selectedLocation : "home base",
        ],
      robot_name: savedNickname,
      // network_status: "Online",
      // battery_amount: currBatteryAmount.toString(),
      wifi_status: "Online", 
      // next_tasks: "3", 
      is_approved: false,
      is_running: false
    };
    await updateDoc(docRef, action);
    setSelectedLocation('');
  }

  const escortUser = async () => {
        const logPath = `Companies/Zeeno_Dev/Actions/${savedRobot}/Logs`;
        const collectionRef = collection(db, logPath);
                
        // Create a new document in the collection and get its ID
        const newDocRef = await addDoc(collectionRef, {});
                
        // Create a reference to the document we just created
        const docRef = doc(db, `${logPath}/${newDocRef.id}`);
                
        // Create the log entry object with the dynamic areaSelected
        const action = {
          by: userName,
          description: "Escorting " + escortNameSaved,
          status: "upcoming",
          timestamp: Timestamp.now(), // Use Timestamp.now() for the current time
          content: [
            "escort",
            escortNameSaved,
            escortFromSaved, 
            escortToSaved
            ],
          robot_name: savedNickname,
          // network_status: "Online",
          // battery_amount: currBatteryAmount.toString(),
          wifi_status: "Online", 
          // next_tasks: "3", 
          is_approved: false,
          is_running: false
        };
                
        // Update the newly created document with the log entry data
        await updateDoc(docRef, action);
        console.log('Document successfully updated with ID: ', newDocRef.id);
  }

  const fetchUser = async () => {
    // Define the new path to the settings document
    const settingsPath = `/Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/actions`;
    const settingsDocRef = doc(db, settingsPath);
    
    try {
        // Fetch the settings document to get the area_selected field
        const settingsDocSnap = await getDoc(settingsDocRef);
        
        if (settingsDocSnap.exists()) {
            const settingsData = settingsDocSnap.data();
            const areaSelected = settingsData.current_location || "unknown"; // Fallback if area_selected is undefined
            
            // Define the path to the logs collection
            const logPath = `Companies/Zeeno_Dev/Actions/${savedRobot}/Logs`;
            const collectionRef = collection(db, logPath);
            
            // Create a new document in the collection and get its ID
            const newDocRef = await addDoc(collectionRef, {});
            
            // Create a reference to the document we just created
            const docRef = doc(db, `${logPath}/${newDocRef.id}`);
            
            // Create the log entry object with the dynamic areaSelected
            const action = {
                by: userName,
                description: "Fetching " + fetchItemSaved + " from " + areaSelected + " to " + fetchFromSaved,
                status: "upcoming",
                timestamp: Timestamp.now(), // Use Timestamp.now() for the current time
                content: [
                    "fetch",
                    fetchItemSaved, 
                    areaSelected,
                    fetchFromSaved, // Use dynamic value here
                    fetchWaitTimeSaved.replace(" sec", "").toString()
                ],
                robot_name: savedNickname,
                // network_status: "Online",
                // battery_amount: currBatteryAmount.toString(),
                wifi_status: "Online", 
                // next_tasks: "3", 
                is_approved: false,
                is_running: false
            };
            
            // Update the newly created document with the log entry data
            await updateDoc(docRef, action);
            
            console.log('Document successfully updated with ID: ', newDocRef.id);
        } else {
            console.log("Settings document does not exist!");
        }
    } catch (error) {
        console.error('Error updating document: ', error);
    }
}


  // const displayMessage = async (type) => {
  //   const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/actions`;
  //   const docRef = doc(db, docPath);
  //   if (type === 'display') {
  //     try {
  //       await updateDoc(docRef, {
  //         display_message: displayedMessage,
  //         toggle_message: true
  //       });
  //     } catch (error) {
  //       console.error('error updated location: ', error);
  //     }
  //   } else if (type === 'announce') {
  //     try {
  //       await updateDoc(docRef, {
  //         announce_message: announcedMessage,
  //         toggle_announce: true
  //       });
  //     } catch (error) {
  //       console.error('error updated location: ', error);
  //     }
  //   }
  // }
  const displayMessage = async (type) => {
    const logPath = `Companies/Zeeno_Dev/Actions/${savedRobot}/Logs`;
    const collectionRef = collection(db, logPath);      
    // Create a new document in the collection and get its ID
    const newDocRef = await addDoc(collectionRef, {});         
    // Create a reference to the document we just created
    const docRef = doc(db, `${logPath}/${newDocRef.id}`);          
    // Create the log entry object with the dynamic areaSelected
    const action = {
      by: userName,
      description: type === 'message' ? "Display Message" : "Announce Message",
      status: "upcoming",
      timestamp: Timestamp.now(), // Use Timestamp.now() for the current time
      content: [
        type === 'message' ? "message" : "announcement",
        type === 'message' ? displayedMessage : announcedMessage,
        ],
      robot_name: savedNickname,
      // network_status: "Online",
      // battery_amount: currBatteryAmount.toString(),
      wifi_status: "Online", 
      // next_tasks: "3", 
      is_approved: false,
      is_running: false
    };
    await updateDoc(docRef, action);
    console.log('Document successfully updated with ID: ', newDocRef.id);
}

  const sendQR = async () => {
    const logPath = `Companies/Zeeno_Dev/Actions/${savedRobot}/Logs`;
    const collectionRef = collection(db, logPath);      
    // Create a new document in the collection and get its ID
    const newDocRef = await addDoc(collectionRef, {});         
    // Create a reference to the document we just created
    const docRef = doc(db, `${logPath}/${newDocRef.id}`);          
    // Create the log entry object with the dynamic areaSelected
    const action = {
      by: userName,
      description: "Show QR ",
      status: "upcoming",
      timestamp: Timestamp.now(), // Use Timestamp.now() for the current time
      content: [
        "qr",
        qrUrlSaved,
        qrTitleSaved,
        ],
      robot_name: savedNickname,
      // network_status: "Online",
      // battery_amount: currBatteryAmount.toString(),
      wifi_status: "Online", 
      // next_tasks: "3", 
      is_approved: false,
      is_running: false
    };
    await updateDoc(docRef, action);
    console.log('Document successfully updated with ID: ', newDocRef.id);



    // const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/actions`;
    // const docRef = doc(db, docPath);
    // console.log(qrUrlSaved);
    // try {
    //   await updateDoc(docRef, {
    //     qrcode_title: qrTitleSaved,
    //     qrcode_url: qrUrlSaved,
    //     is_qrshow: true
    //   });
    // } catch (error) {
    //   console.error('error updated location: ', error);
    // }
  }
  const confirmAppointmentGPS = async (appointmentId) => {

    console.log("Saved robot: ", savedRobot);
    const practiceIdPath = `Companies/Zeeno_Dev/Dolphin/${savedRobot}`;
    const practiceIdRef = doc(db, practiceIdPath);
    
    // Function to get the practiceid and dolphin_api_key fields
    const getPracticeIdAndApiKey = async () => {
      try {
        const docSnap = await getDoc(practiceIdRef);
        if (docSnap.exists()) {
          const practiceId = docSnap.data().practiceid;
          const dolphinApiKey = docSnap.data().dolphin_api_key;
          console.log('Practice ID:', practiceId);
          console.log('Dolphin API Key:', dolphinApiKey);
          return { practiceId, dolphinApiKey };
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error getting document:', error);
      }
    };
  
    const updateAppointment = async (bearerKey) => {
      const { practiceId, dolphinApiKey } = await getPracticeIdAndApiKey();
      let data = qs.stringify({});
      const config = {
        method: 'put',
        url: `https://dolphin-ocean-api-1.servicebus.windows.net/${practiceId}/dapi/AppointmentGpsStatus?appointmentId=${appointmentId}&gpsStatusId=c5782f1a-0557-4eea-8143-ba5a23feb59e`,
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${dolphinApiKey}`,
        },
        data: data
      };
  
      try {
        const response = await axios.request(config);
        console.log("Response data:", JSON.stringify(response.data));
      } catch (error) {
        console.error("Error:", error);
      }
    };
  
    onSnapshot(practiceIdRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        console.log('Bearer updated ' + data.key);
        updateAppointment(data.key);
      } else {
        console.log("Document does not exist!");
      }
    }, (error) => {
      console.error("Error listening to document: ", error);
    });
  
    const appointmentsCollectionPath = `Companies/Zeeno_Dev/Dolphin/${savedRobot}/Appointments`;
    const appointmentsCollectionRef = collection(db, appointmentsCollectionPath);
    const q = query(appointmentsCollectionRef, where("Id", "==", appointmentId));
  
    try {
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const docToUpdate = querySnapshot.docs[0];
        await updateDoc(docToUpdate.ref, {
          Confirmed: true,
        });
        console.log(`Appointment with ID ${appointmentId} updated to confirmed status: true`);
      } else {
        console.log(`No appointment found with ID ${appointmentId}`);
      }
    } catch (error) {
      console.error('Error updating confirmed status: ', error);
    }
  };

  // const confirmAppointmentGPS = async (appointmentId) => {
  //   const docPath = `Companies/Zeeno_Dev/Dolphin/api`;
  //   const docRef = doc(db, docPath);
  //   let data = qs.stringify({});
  //   onSnapshot(docRef, (docSnapshot) => {
  //     if (docSnapshot.exists()) {
  //       const data = docSnapshot.data();
  //       setBearerKey(data.key);
  //       console.log('Bearer updated ' + data.key);
  //     } else {
  //       console.log("Document does not exist!");
  //     }
  //   }, (error) => {
  //     console.error("Error listening to document: ", error);
  //   });

  //   const config = {
  //     method: 'put',
  //     url: `https://dolphin-ocean-api-1.servicebus.windows.net/0B0C537C0D4744E1B84D3E0D50E247AE/dapi/AppointmentGpsStatus?appointmentId=${appointmentId}&gpsStatusId=c5782f1a-0557-4eea-8143-ba5a23feb59e`,
  //     headers: {
  //       'Accept': 'application/json',
  //       'Authorization': `Bearer ${bearerKey}`
  //     },
  //     data: data
  //   };

  //   try {
  //     const response = await axios.request(config);
  //     console.log("Response data:", JSON.stringify(response.data));
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }

  //   const appointmentsCollectionPath = `Companies/Zeeno_Dev/Dolphin/${savedRobot}/Appointments`;
  //   const appointmentsCollectionRef = collection(db, appointmentsCollectionPath);
  //   const q = query(appointmentsCollectionRef, where("id", "==", appointmentId));

  //   try {
  //     const querySnapshot = await getDocs(q);
  //     if (!querySnapshot.empty) {
  //       const docToUpdate = querySnapshot.docs[0];
  //       await updateDoc(docToUpdate.ref, {
  //         Confirmed: true,
  //       });
  //       console.log(`Appointment with ID ${appointmentId} updated to confirmed status: true`);
  //     } else {
  //       console.log(`No appointment found with ID ${appointmentId}`);
  //     }
  //   } catch (error) {
  //     console.error('Error updating confirmed status: ', error);
  //   }
  // };

  const sendCheckinPatients = async () => {
    savedPatientList.forEach(item => {
      checkedIds.forEach(selectedId => {
        if (item.Id === selectedId) {
          console.log("Is this working?");
          confirmAppointmentGPS(item.Id);
        } else {
          console.log("items is " + item.Id);
          console.log("selected id is " + selectedId)
        }
      });
    });
    //confirmAppointmentGPS(savedPatientList[0].Id)
    // console.log("Your checked ids are: " + checkedIds );
  }

  const sendCheckedinPatients = async () => {

    //confirmAppointmentGPS(savedPatientList[0].Id)
    // console.log("Your checked ids are: " + checkedIds );
  }

  return (
    <div className='bg-[#F0F2FF] px-10 py-6 lg:h-[600px] 2xl:h-full overflow-auto'>
      <GotoPopup isOpen={gotoStatus} onClose={() => setGotoStatus(false)} onSend={() => sendToLocation()} locations={locArray}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Go To</h2>
        <p className="text-md text-gray-800 font-heebo font-light mt-1">{`Please choose ${savedNickname} destination`}</p>
      </GotoPopup>
      <EscortPopup currentLocation={kioskLoc} isOpen={escortStatus} onClose={() => setEscortStatus(false)} onSend={() => escortUser()} locations={locArray}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Escort</h2>
        <p className="text-md text-gray-800 font-heebo font-light mt-1">{`Please set ${savedNickname} escort locations:`}</p>
      </EscortPopup>
      <FetchPopup currentLocation={kioskLoc} isOpen={fetchStatus} onClose={() => setFetchStatus(false)} onSend={() => fetchUser()} locations={locArray}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Fetch</h2>
        <p className="text-md text-gray-800 font-heebo font-light mt-1">{`Please set ${savedNickname} fetch locations:`}</p>
      </FetchPopup>
      <AnnouncePopup isOpen={showAnnounce} onClose={() => setShowAnnounce(false)} onSend={() => displayMessage('announce')}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Announce Message</h2>
        <p className="text-md text-gray-800 font-heebo font-light mt-1">{`Enter message to announce on ${savedNickname}`}</p>
      </AnnouncePopup>
      <MessagePopup isOpen={showMessageStatus} onClose={() => setShowMessageStatus(false)} onSend={() => displayMessage('message')}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Display Message</h2>
        <p className="text-md text-gray-800 font-heebo font-light mt-1">{`Enter message to display on ${savedNickname}`}</p>
      </MessagePopup>
      <QRPopup isOpen={showQRStatus} onClose={() => setShowQRStatus(false)} onSend={() => sendQR()}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Add QR Code</h2>
      </QRPopup>
      <ComeHerePopup isOpen={comeHereStatus} onClose={() => setComeHereStatus(false)} onSend={() => sendToLocation()} locations={locArray} currentLocation={currentLoc}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Come Here</h2>
        <p className="text-md text-gray-800 font-heebo font-light mt-1">{`Please set ${savedNickname} destination location:`}</p>
      </ComeHerePopup>
      <CheckinPopup isOpen={checkinStatus} onClose={() => setCheckinStatus(false)} onSend={() => sendCheckinPatients()}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Check In Patient</h2>
        <p className="text-md text-gray-800 font-heebo font-light mt-1">{`Please set ${savedNickname} to check in patient`}</p>
      </CheckinPopup>
      <CheckedinPopup isOpen={checkedinStatus} onClose={() => setCheckedinStatus(false)} onSend={() => sendCheckedinPatients()} userName={userName} savedNickname={savedNickname} batteryAmount={currBatteryAmount} locations={locArray}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Checked In Patients</h2>
        <p className="text-md text-gray-800 font-heebo font-light mt-1">{`Please set ${savedNickname} to escort patient`}</p>
      </CheckedinPopup>
      <Header />
      <h1 className='font-heebo text-xl 2xl:mt-14 lg:mt-8 text-[#273a84]'>Actions</h1>
      <ActionList />
      {isTaskVisible && <div>
        <h1 className='font-heebo text-xl 2xl:mt-36 lg:mt-10 text-[#273a84]'>Current Task</h1>
        <Footer taskId={taskId} robotName={savedNickname} networkStatus={currNetworkStatus} batteryAmount={currBatteryAmount} wifiStatus={currWifiStatus} nextTasks={currNextTasks} task={currTaskType} taskContent={currTaskContent} status={currStatus} />
      </div>}
    </div>
  )
}

const ActionList = () => {
  const [pressedItemId, setPressedItemId] = useState(null);
  const [savedRobot] = useAtom(currentRobot);
  const setIsGotoOpen = useSetAtom(isGotoOpen);
  const setIsEscortOpen = useSetAtom(isEscortOpen);
  const setIsFetchOpen = useSetAtom(isFetchOpen);
  const setIsShowMsgOpen = useSetAtom(isDisplayMessageOpen);
  const setIsShowAnnounce = useSetAtom(isShowAnnounce);
  const setIsShowQRCode = useSetAtom(isQrOpen);
  const setIsShowComeHere = useSetAtom(isComeHereOpen);
  const setIsShowCheckin = useSetAtom(isCheckinOpen);
  const setIsShowCheckedin = useSetAtom(isCheckedinOpen);
  const setSelectedPage = useSetAtom(selectedPage);
  const setIsPageChanged = useSetAtom(isPageChanged);
  const setCheckedIds = useSetAtom(checkedPatientIds);


  function setupFollowMeListener(savedRobot, db) {
    if (savedRobot !== '') {
      const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/actions`;
      const docRef = doc(db, docPath);

      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          const followMe = docSnap.data().follow_me;
          if (followMe === false) {
            console.log("value was reset to false");
          }
        } else {
          console.log("No such document!");
        }
      }, (error) => {
        console.error("Error listening to document: ", error);
      });

      return unsubscribe; // This function can be called to stop listening to changes
    } else {
      return null;
    }
  }

  useEffect(() => {
    const unsubscribe = setupFollowMeListener(savedRobot, db);
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedRobot, db]); // Dependencies array

  const onActionClicked = async (index, name) => {
    if (savedRobot !== '') {
      //const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/actions`;
      //const docRef = doc(db, docPath);
      switch (name) {
        // case "Follow Me":
        //   try {
        //     await updateDoc(docRef, {
        //       follow_me: true
        //     });
        //   } catch (error) {
        //     console.error('Error updating document: ', error);
        //   }
        //   break;
        case "Go To":
          setIsGotoOpen(true);
          break;
        case "Escort":
          setIsEscortOpen(true);
          break;
        case "Fetch":
          setIsFetchOpen(true);
          break;
        case "Announce":
          setIsShowAnnounce(true);
          break;
        case "Show Message":
          setIsShowMsgOpen(true);
          break;
        // case "Clear Screen":
        //   try {
        //     await updateDoc(docRef, {
        //       toggle_clear: true
        //     });
        //   } catch (error) {
        //     console.error('Error updating document: ', error);
        //   }
        //   break;
        case "Play Video":
          setIsPageChanged(true);
          setSelectedPage(3);
          break;
        case "Show QR Code":
          setIsShowQRCode(true);
          break;
        case "Come Here":
          setIsShowComeHere(true);
          break;
        case "Check In Patient":
          setCheckedIds([])
          setIsShowCheckin(true);
          break;
          case "Checked In Patients":
            setCheckedIds([])
            setIsShowCheckedin(true);
            break;
        default:
          break;
      }
    }
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mt-7 w-fit select-none">
      {actionBtns.map((item, index) => (
        <div
          key={index}
          className="bg-[#3C5BD2] bg-opacity-10 rounded-lg 2xl:py-7 2xl:px-9 lg:py-5 lg:px-7 flex flex-col items-center hover:bg-opacity-5 cursor-pointer active:bg-opacity-100"
          onClick={() => onActionClicked(index, item.text)}
          onMouseDown={() => setPressedItemId(index)}
          onMouseUp={() => setPressedItemId(null)}
        >
          <IconTint src={item.image} alt="" color={pressedItemId === index ? '#FFFFFF' : '#3C5BD2'} className="w-full 2xl:h-12 lg:h-8 object-contain rounded-md" />
          <p className={`mt-4 font-heebo 2xl:text-xl lg:text-md ${pressedItemId === index ? 'text-white' : 'text-[#273a84]'}`}>{item.text}</p>
        </div>
      ))}
    </div>
  )
}

export default ActionsFragment