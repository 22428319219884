import { atom } from "jotai"
import {atomWithStorage} from 'jotai/utils'

// -- General --
export const selectedPage = atom(0);
export const isPageChanged = atom(false);
export const currentRobot = atom('');
export const robotNickname = atom('Choose Robot');
export const locationArray = atom([]);
export const appVersion = atom('');
export const loggedInUser = atomWithStorage('user','',undefined,{getOnInit:true});

// -- Actions Fragment -- 
export const isGotoOpen = atom(false);
export const isEscortOpen = atom(false);
export const isFetchOpen = atom(false);
export const isDisplayMessageOpen = atom(false);
export const isQrOpen = atom(false);
export const isComeHereOpen = atom(false);
export const isCheckinOpen = atom(false);
export const isCheckedinOpen = atom(false);
export const savedSelectedLoc = atom('');
export const savedMessage = atom('');
export const savedAnnounced = atom('');
export const isShowAnnounce = atom(false);

// -- Users Fragment --
export const isAddUserOpen = atom(false);


// -- Escort Popup -- 
export const escortFrom = atom('');
export const escortTo = atom('');
export const escortName = atom('');

// -- Fetch Popup -- 
export const fetchItem = atom('Example Item');
export const fetchWaitTime = atom('60 sec')
export const fetchFrom = atom('')
// In DataStore.js
export const fetchTo = atom('');

// -- QR Popup -- 
export const qrCodeURL = atom('');
export const qrCodeTitle = atom('');
export const webUrl = atom('');

// -- Settings Fragment --
export const volumeAmount = atom('');
export const showKioskTimeout = atom(false);
export const kioskTimeout = atom(0);
export const showKioskLocations = atom(false);
export const selectedKioskLoc = atom('');
export const selectedWarningPercent = atom(0);
export const showWarningPopup = atom(false);
export const selectedChargePercent = atom(0);
export const showChargePopup = atom(false);
export const selectedChargedPercent = atom(0);
export const showChargedPopup = atom(false);

// -- Checkin Popup --
export const currentPatientList = atom([]);
export const checkedPatientIds = atom([]);

// -- New User Popup -- 





