import React, { useEffect, useState } from 'react';
import escortIcon from '../../assets/icons/ic_escort.svg'
import IconTint from 'react-icon-tint';
import Dropdown from '../Dropdown';
import { useAtom } from 'jotai';
import { fetchFrom, fetchTo, fetchItem, fetchWaitTime } from '../store/DataStore';

const waitTimeArr = ['Wait time', '30 sec', '60 sec', '90 sec']

const FetchPopup = ({ isOpen, children, onClose, onSend, locations, currentLocation }) => {
    const [show, setShow] = useState(isOpen);
    const [isDropdownTouched, setIsDropdownTouched] = useState(false)
    const [selectedWaitTime, setSelectedWaitTime] = useAtom(fetchWaitTime);
    const [selectedFromLocation, setSelectedFromLocation] = useAtom(fetchFrom);
    const [selectedToLocation, setSelectedToLocation] = useAtom(fetchTo);
    const [itemFetched, setItemFetched] = useAtom(fetchItem);

    const sortedLocations = locations
        .filter(location => location !== "home base")
        .sort((a, b) => a.localeCompare(b));

    if (locations.includes("home base")) {
        sortedLocations.unshift("home base");
    }

    useEffect(() => {
        if (isOpen) {
            setShow(true);
            if (!isDropdownTouched) {
                setSelectedWaitTime(waitTimeArr[2]);
                setSelectedToLocation(currentLocation);
                setSelectedFromLocation(locations[0]);
            }
        } else {
            setShow(false);
            setTimeout(onClose, 200);
        }
    }, [isOpen, onClose, isDropdownTouched, currentLocation, locations, setSelectedWaitTime, setSelectedToLocation, setSelectedFromLocation]);
    

    const handleClose = () => {
        setShow(false);
        setTimeout(onClose, 200);
        setItemFetched('');
        setSelectedWaitTime(selectedWaitTime);
        setSelectedFromLocation(selectedFromLocation);
        setSelectedToLocation(selectedToLocation);
        setIsDropdownTouched(false);
    };

    const handleSend = () => {
        onSend();
        setShow(false);
        setTimeout(onClose, 200);
        setItemFetched('');
        setSelectedWaitTime(selectedWaitTime);
        setSelectedFromLocation(selectedFromLocation);
        setSelectedToLocation(selectedToLocation);
        setIsDropdownTouched(false);
    };

    const setSelectedFrom = (item) => {
        setIsDropdownTouched(true);
        setSelectedFromLocation(item);
    }

    const setSelectedTo = (item) => {
        setIsDropdownTouched(true);
        setSelectedToLocation(item);
    }

    const setSelectedTime = (item) => {
        setIsDropdownTouched(true);
        setSelectedWaitTime(item);
    }

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-[full] transition-opacity duration-200 ${show ? 'opacity-100' : 'opacity-0'}`}
            onClick={handleClose}
            style={{ display: show || isOpen ? 'block' : 'none' }}
        >
            <div
                className="relative top-20 mx-auto p-4 border w-fit shadow-lg rounded-md bg-white ml"
                onClick={e => e.stopPropagation()}
            >
                <div className="flex justify-end">
                    <button onClick={handleClose} className="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-0.5 text-sm ml-auto inline-flex items-center">
                        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="-mt-4 flex flex-col items-center">
                    {children}
                </div>
            <div className='flex flex-col gap-4 mt-2 mb-5'>
                <div className='flex flex-row gap-4'>
                    <div className='flex-1'>
                    <span className='font-heebo text-md font-light'>Item</span>
                        <input className='bg-[#3C5BD2] bg-opacity-10 px-2 py-4 h-7 mt-1 rounded-md w-full outline-none font-heebo font-light' 
                            placeholder='Item description' 
                            value={itemFetched} 
                            onChange={(e) => setItemFetched(e.target.value)} />
                    </div>
                    <div className='w-[150px]'>
                        <span className='font-heebo text-md font-light'>Wait Time</span>
                        <Dropdown list={waitTimeArr} setSelected={(item) => setSelectedTime(item)} selectedItem={selectedWaitTime} dropdownWidth={"150"} />
                    </div>
                </div>
                <div className='flex flex-row gap-4'>
                    <div>
                        <span className='font-heebo text-md font-light'>From</span>
                        <Dropdown list={sortedLocations} setSelected={(item) => setSelectedFrom(item)} selectedItem={selectedFromLocation} dropdownWidth={"250"} />
                    </div>
                    <div>
                        <span className='font-heebo text-md font-light'>To</span>
                        <Dropdown list={sortedLocations} setSelected={(item) => setSelectedTo(item)} selectedItem={selectedToLocation} dropdownWidth={"250"} />
                    </div>
                </div>
            </div>
                <div className='w-full flex justify-center mt-14'>
                    {(selectedFromLocation !== selectedToLocation && selectedWaitTime !== "Wait time" && itemFetched !== '') ?
                        <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center cursor-pointer' onClick={handleSend}>
                            <span className='text-white font-heebo'>Confirm Fetch</span>
                            <IconTint src={escortIcon} className='h-5 ml-2' color='#FFFFFF' />
                        </div>
                        :
                        <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center opacity-50'>
                            <span className='text-white font-heebo'>Confirm Fetch</span>
                            <IconTint src={escortIcon} className='h-5 ml-2' color='#FFFFFF' />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default FetchPopup;